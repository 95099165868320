<template>
  <div class="card">
    <div class="card-header pt-10 pb-10">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        ข้อมูลร้านค้า / Business Information
      </h3>
      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          :disabled="!canUpdate"
          @click="goToEdit()"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" />
          </span>
          {{ !isEdit ? "แก้ไขข้อมูล" : "บันทึกข้อมูล" }}
        </button>
      </div>
    </div>

    <div class="card-title row ms-6 me-5 mt-10">
      <div class="col-12 col-md-5 col-sm-5">
        <div class="col-12 mb-3">
          <label for="docNumber" class="form-label col-sm-10"
            >Business ID</label
          >
          <input
            v-model="form.company_business_id"
            class="form-control"
            id="discountPerUnit"
            disabled
          />
        </div>

        <div class="col-12 mb-3">
          <label for="docNumber" class="form-label col-sm-10">ชื่อกิจการ</label>
          <input
            v-model="form.company_name"
            :disabled="isEdit ? false : true"
            class="form-control"
            id="discountPerUnit"
          />
        </div>

        <div class="col-12 mb-3">
          <label for="docNumber" class="form-label col-sm-10"
            >ชื่อสาขากิจการ</label
          >
          <input
            v-model="form.company_branch"
            :disabled="isEdit ? false : true"
            class="form-control"
            id="discountPerUnit"
          />
        </div>

        <div class="col-12 mb-3">
          <label for="docNumber" class="form-label col-sm-10"
            >ประเภทกิจการ</label
          >
          <input
            v-model="form.company_type"
            :disabled="isEdit ? false : true"
            class="form-control"
            id="discountPerUnit"
          />
        </div>

        <div class="col-12 mb-3">
          <label for="docNumber" class="form-label col-sm-10">ที่อยู่</label>
          <input
            v-model="form.company_address"
            :disabled="isEdit ? false : true"
            class="form-control"
            id="discountPerUnit"
          />
        </div>

        <!-- <div class="col-12 mb-3">
          <label for="docNumber" class="form-label col-sm-10">จังหวัด</label>
          <input :disabled="isEdit falseue:true" class="form-control" id="discountPerUnit" />
        </div> -->

        <div class="col-12 mb-3">
          <label for="docNumber" class="form-label col-sm-10"
            >เบอร์โทรศัพท์</label
          >
          <input
            v-model="form.company_tel"
            :disabled="isEdit ? false : true"
            class="form-control"
            id="discountPerUnit"
          />
        </div>

        <div class="col-12 mb-3">
          <label for="docNumber" class="form-label col-sm-10">อีเมล</label>
          <input
            v-model="form.company_email"
            :disabled="isEdit ? false : true"
            class="form-control"
            id="discountPerUnit"
          />
        </div>

        <div class="col-12 mb-3">
          <label for="docNumber" class="form-label col-sm-10">ไลน์ไอดี</label>
          <input
            v-model="form.company_line_id"
            :disabled="isEdit ? false : true"
            class="form-control"
            id="discountPerUnit"
          />
        </div>

        <div class="col-12 mb-7">
          <label for="docNumber" class="form-label col-sm-10"
            >Digital Address</label
          >
          <input
            v-model="form.digital_address"
            :type="role === 'BUSINESS OWNER' ? 'text' : 'password'"
            disabled
            class="form-control"
            id="discountPerUnit"
          />
        </div>

        <div class="col-12 mb-7">
          <div
            class="form-check form-switch form-check-custom form-check-solid"
          >
            <input
              v-model="form.status"
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexSwitchDefault"
              style="height: 1.8rem"
              :disabled="
                role === 'SYSTEM ADMIN' ? false : role === 'ROOT' ? false : true
              "
              @change="activeChange()"
            />
            <label class="form-check-label" for="flexSwitchDefault"
              >เปิดใช้งาน (Active)</label
            >
          </div>
        </div>
      </div>

      <div class="col-12 col-md-7 col-sm-7">
        <div class="col-12 mb-10">
          <label for="docNumber" class="form-label col-sm-10"
            >โลโก้กิจการ</label
          >
          <div class="d-flex justify-content-center">
            <img
              class="shadow-sm"
              alt="Logo"
              :src="imgShow ? imgShow : 'media/avatars/blank.png'"
              style="
                border-radius: 49%;
                border: 1.5px solid Gainsboro;
                width: 140px;
              "
            />
            <i
              @click="onClickUpload()"
              class="bi bi-camera"
              style="font-size: 23px; cursor: pointer"
            >
              <input
                @change="onFileChange($event)"
                type="file"
                id="input-img"
                style="display: none"
                accept="image/png, image/jpeg"
              />
            </i>
          </div>
        </div>

        <AddItem
          :dialogAddItem="dialogAddItem"
          :dataEditItem="dataEditItem"
          @submitAddItem="submitAddItem"
          @closeDialogAddItem="closeDialogAddItem"
        />

        <hr style="color: grey" />
      </div>
    </div>
  </div>
</template>

<script>
import userApi from "@/api/user/";
import apiConfig from "@/config/api";

import Swal from "sweetalert2/dist/sweetalert2.js";
import { Decode, Encode } from "@/services";

import AddItem from "../../components/upload/dialogCropImg.vue";

export default {
  components: {
    AddItem,
  },
  setup() {
    document.title = "BELUCA | ข้อมูลร้านค้า";
  },
  data: () => ({
    userId: "",
    user: [],
    classRole: "badge badge-lg badge-light-success fw-bolder fs-6",

    canUpdate: false,
    dialogAddItem: false,

    dataEditItem: "",

    center: { lat: 51.093048, lng: 6.84212 },
    markers: [
      {
        position: {
          lat: 51.093048,
          lng: 6.84212,
        },
      }, // Along list of clusters
    ],

    isEdit: false,

    form: {
      company_address: "",
      company_branch: "",
      company_code: "",
      company_digital_id: "",
      company_email: "",
      company_line_id: "",
      company_logo: "",
      company_name: "",
      company_number: "",
      company_position: "",
      company_tel: "",
      company_type: "",
    },

    branchId: "",

    imgName: "",

    role: "",

    imgShow: "",
  }),
  created() {
    this.getCompany();
    this.getBranch();

    this.role = Decode.decode(localStorage.getItem("role")).replace(
      /^"(.*)"$/,
      "$1"
    );
    this.checkRole();
  },
  methods: {
    async getCompany() {
      const id = this.$route.query.id;

      const response = await userApi.company.getOne(id);

      if ((response.response_status = "SUCCESS")) {
        this.form = response.data;
      }
    },
    async getBranch() {
      const id = this.$route.query.id;
      const companyBranchId = localStorage.getItem("companyBranchId");

      const response = await userApi.companyBranch.getOne(companyBranchId);

      if ((response.response_status = "SUCCESS")) {
        this.form.company_branch = response.data.company_branch_name;
        this.branchId = response.data.id;
        this.imgShow = response.data.file
          ? `${apiConfig.ENDPOINT}${response.data.file.path}`
          : "media/avatars/blank.png";
      } else {
        this.form.company_branch = "";
      }
    },

    checkRole() {
      if (this.role === "BUSINESS OWNER" || this.role === "SYSTEM ADMIN") {
        this.canUpdate = true;
      } else {
        this.canUpdate = false;
      }
    },

    goToEdit() {
      if (this.isEdit) {
        this.updateCompany();
        this.isEdit = false;
      } else {
        this.isEdit = true;
      }
    },

    async updateCompany() {
      const response = await userApi.company.update(this.form.id, this.form);
      if ((response.response_status = "SUCCESS")) {
        Swal.fire({
          icon: "success",
          title: "แก้ไขสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    async activeChange() {
      const response = await userApi.company.update(this.form.id, {
        status: this.form.status,
      });

      if (response.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "อัพเดทสถานะสำเร็จ",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "อัพเดทสถานะไม่สำเร็จ",
          showConfirmButton: false,
          timer: 1700,
        });
      }
    },

    // product
    addItem(pic) {
      this.dialogAddItem = true;
      this.dataEditItem = pic;
    },

    closeDialogAddItem() {
      this.dialogAddItem = false;
    },

    async submitAddItem(item) {
      this.closeDialogAddItem();
      this.imgShow = item.canvas.toDataURL(this.image.type);
      let file = await this.dataURLtoFile(this.imgShow, this.imgName);
      if (file) {
        this.uploadPic(file);
      }
    },

    // upload pic
    onClickUpload() {
      document.getElementById("input-img").click();
    },

    async onFileChange(e) {
      const selectImg = e.target.files[0];
      this.imgName = selectImg.name;
      const response = await this.createBase64Img(selectImg);

      if (response) {
        this.image = response;
        this.addItem(response);
      }
    },

    async createBase64Img(file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    },

    async dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },

    async uploadPic(file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "upload-file");
      formData.append("name", file.name);
      formData.append("size", file.size);
      formData.append("companyBranchId", this.branchId);

      const response = await userApi.upload.upload(formData);

      if (response.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: `อัพโหลดรูปภาพสำเร็จ`,
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          this.$router.go(0);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: `อัพโหลดรูปภาพไม่สำเร็จ`,
          confirmButtonText: "ตกลง",
        });
      }
    },
  },
};
</script>
